<template>
  <inline-select
    :id="`select:${$helpers.str2_(properties.name)}`"
    :value="value"
    :label="properties.name"
    :readonly="properties.readonly"
    :options="options"
    :allow-empty="properties.allowEmpty"
    :multiple="properties.multiple"
    :mode="mode"
    @changed="onChange"
    :required="properties.required"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Array, String],
      default: () => {}
    },

    properties: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      options: []
    }
  },
  computed: {
    mode () {
      return this.$form.mode(this)
    }
  },
  created () {},
  async mounted () {
    if (!this.properties.optionsService)
      console.error(
        `FormSelect '${this.properties.name}' has undefined 'optionsService' property`
      )

    this.fetchOptions()
  },
  methods: {
    async fetchOptions () {
      let response = await this.properties.optionsService()
      this.options = response.map(i => ({
        id: i.id,
        label: i.name
      }))
    },

    onChange (id, value) {
      this.$emit('input', value)

      this.$emit('change', { id, value })
    }
  }
}
</script>

<style></style>
