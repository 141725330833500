import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

//import "./includes/core-js.js";

import './includes/libraries.js'

import './includes/fa-icons.js'

import './includes/shared.js'

import './includes/prototypes.js'

import './includes/error-handlers.js'

import './includes/components.js'

import { AUTH_LOGOUT } from '@/store/actions/auth'

const token = store.getters['authToken']

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
  data: {
    bus: new Vue({})
  },
  created: async function () {
    let _self = this

    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (
          err.response &&
          (err.response.status === 400 || err.response.status === 401) &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          _self.$store.dispatch(AUTH_LOGOUT)

          if (_self.$route.path !== '/login') _self.$router.push('/login')
        } else {
          console.log('Connection error', err)
        }
        throw err
      })
    })

    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch('timeTracker/fetchStatus')
    }
  },
  methods: {}
})
