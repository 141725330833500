import CryptoJS from 'crypto-js'
import moment from 'moment'

import {
  //TASK_STATUSES,
  //TASK_FREQUENCY,
  LOGICAL_OPERATORS,
  DATETIME_TEMPLATES
} from '@/shared/constants'

Array.prototype.sum = function (prop) {
  var total = 0
  for (var i = 0, _len = this.length; i < _len; i++) {
    let next = parseFloat(this[i][prop])

    total += isNaN(next) ? 0 : next
  }
  return total
}

const helpers = {
  encodeURI (data) {
    return encodeURIComponent(data)
  },
  decodeURI (data) {
    return decodeURIComponent(data)
  },
  encrypt (data) {
    return CryptoJS.RC4.encrypt(data, process.env.VUE_APP_SECRET_KEY).toString()
  },

  decrypt (data) {
    let bytes = CryptoJS.RC4.decrypt(data, process.env.VUE_APP_SECRET_KEY)

    let decryptedData = bytes.toString(CryptoJS.enc.Utf8)

    return decryptedData
  },
  mapDictionaryOptions (response) {
    return response.map(u => ({
      id: u.id,
      label: u.name
    }))
  },
  debounce (fn, delay) {
    var timeoutID = null

    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      var that = this

      timeoutID = setTimeout(function () {
        //console.log('debounce1',timeoutID)

        fn.apply(that, args)
      }, delay)

      //console.log('debounce0',timeoutID)
    }
  },

  vw2px (value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth
    //y = w.innerHeight || e.clientHeight || g.clientHeight;

    var result = (x * value) / 100

    return result
  },

  vh2px (value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      //x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight

    var result = (y * value) / 100

    return result
  },

  px2vw (value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth
    //y = w.innerHeight || e.clientHeight || g.clientHeight;

    var result = (100 * value) / x

    return result
  },

  px2vh (value) {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      //x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight

    var result = (100 * value) / y

    return result
  },

  isEmptyObject (obj) {
    return JSON.stringify(obj) === '{}'
  },
  delay (ms) {
    return new Promise(res => setTimeout(res, ms))
  },

  random (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },

  round (num, places = 0) {
    return +(Math.round(num + `e+${places}`) + `e-${places}`)
  },

  str2_ (str) {
    return str.replace(/\s+/g, '_').toLowerCase()
  },

  uid8 () {
    return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 8) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(8)
    })
  },

  uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },

  compareFloat (a, b, ascending) {
    let val1 = parseFloat(a)
    let val2 = parseFloat(b)
    if (ascending) return val1 >= val2 ? 1 : -1
    return val1 <= val2 ? 1 : -1
  },

  compareDate (a, b, ascending = true) {
    let dateA = new Date(a)
    let dateB = new Date(b)
    if (ascending) return dateA >= dateB ? 1 : -1
    return dateA <= dateB ? 1 : -1
  },
  parseType (value) {
    //if (typeof value === "function" && !isNaN(parseFloat(value.replace('$','').replace(',','')))) return 'float'

    let type = 'string'
    if (moment(value, 'YYYY/MM/DD', true).isValid()) {
      type = 'date'
    } else if (moment(value, 'YYYY-MM-DD', true).isValid()) {
      type = 'date'
    } else if (typeof value === 'number') {
      type = 'float'
    } else if (!isNaN(parseFloat(value.replace('$', '').replace(',', '')))) {
      type = 'float'
    }
    //console.log(`parseType: ${value} - ${type}`);

    return type
  },
  parseType2 (value) {
    let type = 'string'

    //console.log(`value: ${value} ...`)

    if (value !== null && typeof value === 'object') {
      type = 'object'
      //  console.log(`value: ${value},${type}`)
      return type
    }

    if (!value) {
      //  console.log(`value: ${value},${type}`)
      return type
    }

    const n = value
      .toString()
      .replace('$', '')
      .replace(',', '')

    //todo 2020-11-06 17:55:27, 2020/10/15, 2020-10-15
    if (!value) {
      type = 'string'
    } else if (moment(value, 'YYYY/MM/DD', true).isValid()) {
      type = 'date'
    } else if (moment(value, 'YYYY-MM-DD', true).isValid()) {
      type = 'date'
    } else if (moment(value, 'MMMM').isValid()) {
      type = 'month'
    } else if (n.indexOf('.') === -1 && Number.isInteger(+n)) {
      type = 'int'
    } else if ((n.indexOf('.') > -1) & Number.isFinite(+n)) {
      type = 'float'
    }

    // console.log(`value: ${value},${type}`)

    //console.log(`parseType2: ${value} - ${type}`);
    //console.log(n,n.indexOf('.'),this.isFloat(+n))

    return type
  },
  isInt (n) {
    //does not work for 1.00
    return Number(n) === n && n % 1 === 0
  },
  isFloat (n) {
    //does not work for 1.00
    return Number(n) === n && n % 1 !== 0
  },
  compare: {
    float: function (a, b, ascending) {
      let val1 = 0
      let val2 = 0

      if (typeof a === 'number') {
        val1 = a
      } else {
        val1 = a ? parseFloat(a.replace('$', '').replace(',', '')) : 0
      }

      if (typeof b === 'number') {
        val2 = b
      } else {
        val2 = b ? parseFloat(b.replace('$', '').replace(',', '')) : 0
      }

      if (ascending) return val1 >= val2 ? 1 : -1
      return val1 <= val2 ? 1 : -1
    },
    date: function (a, b, ascending) {
      let dateA = new Date(a)
      let dateB = new Date(b)
      if (ascending) return dateA >= dateB ? 1 : -1
      return dateA <= dateB ? 1 : -1
    },
    month: function (a, b, ascending) {
      if (!a || !b) return false

      let m1 = parseInt(
        moment()
          .month(a)
          .format('M')
      )
      let m2 = parseInt(
        moment()
          .month(b)
          .format('M')
      )

      if (ascending) return m1 >= m2 ? -1 : 1
      return m1 <= m2 ? -1 : 1
    },
    weekday: function (a, b, ascending) {
      if (!a || !b) return false

      let m1 = parseInt(
        moment()
          .day(a)
          .format('e')
      )
      let m2 = parseInt(
        moment()
          .day(b)
          .format('e')
      )

      if (ascending) return m1 >= m2 ? -1 : 1
      return m1 <= m2 ? -1 : 1
    }
  },
  /**
   * Randomize array element order in-place.
   * Using Durstenfeld shuffle algorithm.
   */
  shuffleArray: array => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  },

  average (dataArray) {
    if (!dataArray) return false

    //get unique set
    dataArray = [...new Set(dataArray)]

    return dataArray.reduce((p, c) => p + c, 0) / dataArray.length
  },

  median (dataArray) {
    if (!dataArray) return false

    //get unique set
    dataArray = [...new Set(dataArray)]

    const mid = Math.floor(dataArray.length / 2),
      nums = [...dataArray].sort((a, b) => a - b)

    return dataArray.length % 2 !== 0
      ? nums[mid]
      : (nums[mid - 1] + nums[mid]) / 2
  },
  parsePeriodNameFromDate (date) {
    let result = ''
    if (/^([1-9][0-9]{3})$/.test(date)) result = 'year'
    if (/^([1-9][0-9]{3}\sQ[1-4])$/.test(date)) result = 'quarter'
    if (/^([a-zA-Z]+\s[1-9][0-9]{3})$/.test(date)) result = 'month'
    if (
      /^(0[1-9]|[12][0-9]|3[01])[/|-]([0-1][0-9])[/|-]([1-9][0-9]{3})$/.test(
        date
      )
    )
      result = 'month' // dd[/-]mm[-/]yyy
    if (
      /^([1-9][0-9]{3})[/|-]([0-1][0-9])[/|-](0[1-9]|[12][0-9]|3[01])$/.test(
        date
      )
    )
      result = 'month' // yyyy[-/]mm[-/]dd

    return result
  },
  parsePeriodFromString (date) {
    let period = ''
    let result = {}

    if (/^([1-9][0-9]{3})$/.test(date)) period = 'year'
    if (/^([1-9][0-9]{3}\sQ[1-4])$/.test(date)) period = 'quarter'
    if (/^([a-zA-Z]+\s[1-9][0-9]{3})$/.test(date)) period = 'month'
    if (
      /^(0[1-9]|[12][0-9]|3[01])[/|-]([0-1][0-9])[/|-]([1-9][0-9]{3})$/.test(
        date
      )
    )
      period = 'month' // dd[/-]mm[-/]yyy
    if (
      /^([1-9][0-9]{3})[/|-]([0-1][0-9])[/|-](0[1-9]|[12][0-9]|3[01])$/.test(
        date
      )
    )
      period = 'month' // yyyy[-/]mm[-/]dd

    if (['year', 'month'].includes(period)) {
      result.startDate = moment(date).startOf(period)
      result.endDate = moment(date).endOf(period)
    }
    if (period === 'quarter') {
      let res = /^(([1-9][0-9]{3})\sQ([1-4]))$/.exec(date)

      let year = res[2]
      let quarter = res[3]

      result.startDate = moment(year)
        .quarter(quarter)
        .startOf('quarter')
      result.endDate = moment(year)
        .quarter(quarter)
        .endOf('quarter')
    }

    return result
  },
  getPivotArray (dataArray, rowIndex, colIndex, dataIndex, sortAlphabet = true) {
    //Code from https://techbrij.com
    var result = {},
      ret = []
    var newCols = []
    let totals = []
    for (let i = 0; i < dataArray.length; i++) {
      if (!result[dataArray[i][rowIndex]]) {
        result[dataArray[i][rowIndex]] = {}
      }
      result[dataArray[i][rowIndex]][dataArray[i][colIndex]] =
        dataArray[i][dataIndex]

      //To get column names
      if (sortAlphabet) {
        if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
          newCols.push(dataArray[i][colIndex])
        }
      } else {
        //1. group by columns and sum values. Prepare for sorting by values sum 2.

        totals = dataArray.reduce(function (r, o) {
          r[o[colIndex]]
            ? (r[o[colIndex]] += o[dataIndex])
            : (r[o[colIndex]] = o[dataIndex])
          return r
        }, [])
      }
    }

    if (sortAlphabet) {
      newCols.sort()
    } else {
      //2. sort categories for area chart ascending (biggest categories at the bottom)
      let keys = Object.keys(totals)
      keys.sort(function (a, b) {
        return totals[b] - totals[a]
      })
      newCols = [...keys]
      ///
    }

    var item = []

    //Add Header Row
    item.push('Item')
    item.push.apply(item, newCols)
    ret.push(item)

    //Add content
    for (var key in result) {
      item = []

      if (Date.parse(key)) {
        item.push(new Date(key))
      } else item.push(key)

      for (let i = 0; i < newCols.length; i++) {
        item.push(result[key][newCols[i]] || 0)
      }
      ret.push(item)
    }

    return ret
  },
  getJSONObject (str) {
    try {
      return JSON.parse(str)
    } catch (e) {
      return false
    }

    //return true;
  },
  getBiWeeklyDatesArray (startDate, endDate = moment(), sort = true) {
    let result = []
    let date = moment(startDate)
    //console.log('date', date)
    //console.log('startDate', startDate)

    endDate = moment(endDate)
    //console.log('endDate', endDate)

    while (date <= endDate) {
      let _startDate = moment(date).format('YYYY-MM-DD')
      date = moment(date)
        .add(1, 'isoWeek')
        .endOf('isoWeek')
      let _endDate = moment(date).format('YYYY-MM-DD')

      //do not include current week
      //if (date > (moment)) break

      result.push({ startDate: _startDate, endDate: _endDate })
      date = moment(date).add(1, 'day')
      // i++;
    }

    if (sort) {
      result = result.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
      //result = result.slice().sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    }

    return result
  },
  getDistinctArray (
    data,
    id,
    name,
    prop_key = 'id',
    prop_value = 'name',
    prop_custom = undefined
  ) {
    let d = data.map(function (item) {
      let obj = {}
      obj[prop_key] = item[id] ? item[id] : ''
      obj[prop_value] = item[name] ? item[name] : ''

      if (prop_custom) obj[prop_custom] = item[prop_custom]

      return obj
    })

    var p = []

    d.forEach(sm => {
      if (!p.some(item => item[prop_key] === sm[prop_key])) p.push(sm)
    })

    p.sort((a, b) => a[prop_value].localeCompare(b[prop_value]))

    return p
  },
  getDateTimeTemplateValue (value) {
    if (value === DATETIME_TEMPLATES.TODAY) return moment()
    if (value === DATETIME_TEMPLATES.TODAY_1Y_AGO)
      return moment().subtract(1, 'year')
    if (value === DATETIME_TEMPLATES.TODAY_2Y_AGO)
      return moment().subtract(2, 'year')

    if (value === DATETIME_TEMPLATES.START_OF_WEEK)
      return moment().startOf('isoWeek')
    if (value === DATETIME_TEMPLATES.END_OF_WEEK)
      return moment().endOf('isoWeek')
    if (value === DATETIME_TEMPLATES.START_OF_MONTH)
      return moment().startOf('month')
    if (value === DATETIME_TEMPLATES.END_OF_MONTH)
      return moment().endOf('month')
    if (value === DATETIME_TEMPLATES.START_OF_QUARTER)
      return moment().startOf('quarter')
    if (value === DATETIME_TEMPLATES.END_OF_QUARTER)
      return moment().endOf('quarter')
    if (value === DATETIME_TEMPLATES.START_OF_YEAR)
      return moment().startOf('year')
    if (value === DATETIME_TEMPLATES.END_OF_YEAR) return moment().endOf('year')

    if (value === DATETIME_TEMPLATES.START_OF_LAST_WEEK)
      return moment()
        .subtract(1, 'week')
        .startOf('isoWeek')
    if (value === DATETIME_TEMPLATES.END_OF_LAST_WEEK)
      return moment()
        .subtract(1, 'week')
        .endOf('isoWeek')
    if (value === DATETIME_TEMPLATES.START_OF_LAST_MONTH)
      return moment()
        .subtract(1, 'month')
        .startOf('month')
    if (value === DATETIME_TEMPLATES.END_OF_LAST_MONTH)
      return moment()
        .subtract(1, 'month')
        .endOf('month')
    if (value === DATETIME_TEMPLATES.START_OF_LAST_QUARTER)
      return moment()
        .subtract(1, 'quarter')
        .startOf('quarter')
    if (value === DATETIME_TEMPLATES.END_OF_LAST_QUARTER)
      return moment()
        .subtract(1, 'quarter')
        .endOf('quarter')
    if (value === DATETIME_TEMPLATES.START_OF_LAST_YEAR)
      return moment()
        .subtract(1, 'year')
        .startOf('year')
    if (value === DATETIME_TEMPLATES.END_OF_LAST_YEAR)
      return moment()
        .subtract(1, 'year')
        .endOf('year')

    if (value === DATETIME_TEMPLATES.SEVEN_DAYS_BEFORE_TODAY)
      return moment().subtract(7, 'days')
    if (value === DATETIME_TEMPLATES.SEVEN_DAYS_AFTER_TODAY)
      return moment().add(7, 'days')
    if (value === DATETIME_TEMPLATES.DAYS_BEFORE_TODAY_45)
      return moment().subtract(45, 'days')
    if (value === DATETIME_TEMPLATES.FIVE_YEARS_BEFORE)
      return moment().subtract(5, 'years')
    if (value === DATETIME_TEMPLATES.TWO_YEARS_AFTER)
      return moment().add(2, 'years')
  },

  applyExpressions2Dataset (expressions, filteredData = [], territories = []) {
    let dataSet = [],
      expressionData = [],
      ruleData = [],
      values = [],
      value = ''

    // if (!filteredData || !filteredData.length) {
    if (!filteredData) {
      throw "applyExpressions2Dataset: filteredData can't be undefined"
    }

    expressions.forEach(se => {
      expressionData = []

      dataSet = filteredData

      //process all rules inside field expression
      se.rules.forEach(r => {
        values = r.selectedValues.map(l => l.label)

        //TERRITORIES LOGIC
        if (se.fieldType === 'territory') {
          //TERRITORY CAN'T BE MULTIPLE FOR A WHILE!
          value = r.selectedValue.label
          //'value' is a territory name, get allowed country/state/city and apply filter
          const t1 = territories.find(t => t.label === value)

          const t2 = JSON.parse(t1.restrictions)

          let territory = {
            countries: [
              ...new Set(t2.filter(i => i.country !== '').map(i => i.country))
            ],
            states: [
              ...new Set(t2.filter(i => i.state !== '').map(i => i.state))
            ],
            cities: [...new Set(t2.filter(i => i.city !== '').map(i => i.city))]
          }

          if (r.logicalOperator === LOGICAL_OPERATORS.STRING.EQUALS) {
            ruleData = dataSet.filter(function (i) {
              if (!value && i[se.fieldTitle] === '') return true

              if (
                territory.countries.includes(i['Country']) &&
                (territory.states.length
                  ? territory.states.includes(i['State'])
                  : true) &&
                (territory.cities.length
                  ? territory.cities.includes(i['City'])
                  : true)
              ) {
                return true
              }
            })
          }

          if (r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL) {
            ruleData = dataSet.filter(function (i) {
              if (!value && i[se.fieldTitle] === '') return true

              if (
                !territory.countries.includes(i['Country']) ||
                !(territory.states.length
                  ? territory.states.includes(i['State'])
                  : true) ||
                !(territory.cities.length
                  ? territory.cities.includes(i['City'])
                  : true)
              ) {
                return true
              }
            })
          }
        }

        // STRING LOGIC
        if (
          se.fieldType === 'string' &&
          r.logicalOperator === LOGICAL_OPERATORS.STRING.EQUALS
        ) {
          //compares full strings, does not work for fields with several values splitted by comma
          //ruleData = dataSet.filter(i => values.length ? values.includes(i[se.fieldTitle]) : i[se.fieldTitle] === '')

          //find field values which contain current rule values (some fields can contain several values splitted by comma, that is why 'string.includes' is used)
          ruleData = dataSet.filter(function (i) {
            if (values.length === 0 && i[se.fieldTitle] === '') return true
            for (let s of values) {
              // 20200825
              if (i[se.fieldTitle] && i[se.fieldTitle]?.includes(s)) return true
              // 20200918
              //if (i[se.fieldTitle] === s) return true;
            }
          })
        }

        if (
          se.fieldType === 'string' &&
          r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_EQUAL
        ) {
          //compares full strings, does not work for fields with several values splitted by comma
          //ruleData = dataSet.filter(i => values.length ? !values.includes(i[se.fieldTitle]) : i[se.fieldTitle] !== '')

          //find field values which does not contain current rule values (some fields can contain several values splitted by comma, that is why 'string.includes' is used)
          ruleData = dataSet.filter(function (i) {
            if (values.length === 0 && i[se.fieldTitle] !== '') return true
            for (let s of values) {
              //20200825
              //if (!i[se.fieldTitle].includes(s)) return true;
              if (i[se.fieldTitle] !== s) return true
            }
          })
        }

        if (
          se.fieldType === 'string' &&
          r.logicalOperator === LOGICAL_OPERATORS.STRING.CONTAINS
        ) {
          ruleData = dataSet.filter(i =>
            i[se.fieldTitle]
              .toLowerCase()
              .includes(r.selectedValue.toLowerCase())
          )
        }

        if (r.logicalOperator === LOGICAL_OPERATORS.STRING.DOES_NOT_CONTAIN) {
          ruleData = dataSet.filter(
            i =>
              !i[se.fieldTitle]
                .toLowerCase()
                .includes(r.selectedValue.toLowerCase())
          )
        }

        // DATETIME LOGIC

        let selectedDateTimeValue = undefined

        if (se.fieldType === 'datetime')
          selectedDateTimeValue = this.getDateTimeTemplateValue(
            r.selectedValue
          ).format('YYYY-MM-DD')

        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator === LOGICAL_OPERATORS.DATETIME.EQUALS
        ) {
          ruleData = dataSet.filter(i =>
            moment(i[se.fieldTitle]).isSame(selectedDateTimeValue)
          )
        }
        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator === LOGICAL_OPERATORS.DATETIME.DOES_NOT_EQUAL
        ) {
          ruleData = dataSet.filter(
            i => !moment(i[se.fieldTitle]).isSame(selectedDateTimeValue)
          )
        }

        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator === LOGICAL_OPERATORS.DATETIME.GREATER_THAN
        ) {
          ruleData = dataSet.filter(i =>
            moment(i[se.fieldTitle]).isAfter(selectedDateTimeValue)
          )
        }
        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator ===
            LOGICAL_OPERATORS.DATETIME.GREATER_THAN_OR_EQUAL_TO
        ) {
          ruleData = dataSet.filter(i =>
            moment(i[se.fieldTitle]).isSameOrAfter(selectedDateTimeValue)
          )
        }
        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator === LOGICAL_OPERATORS.DATETIME.LESS_THAN
        ) {
          ruleData = dataSet.filter(i =>
            moment(i[se.fieldTitle]).isBefore(selectedDateTimeValue)
          )
        }
        if (
          se.fieldType === 'datetime' &&
          r.logicalOperator === LOGICAL_OPERATORS.DATETIME.LESS_THAN_OR_EQUAL_TO
        ) {
          ruleData = dataSet.filter(i =>
            moment(i[se.fieldTitle]).isSameOrBefore(selectedDateTimeValue)
          )
        }

        // MATCHING LOGIC

        //if match All rules then
        if (se.matchingLogic === 'All') {
          expressionData = ruleData

          dataSet = expressionData
        }

        //if match Any rule then combine data
        if (se.matchingLogic === 'Any') {
          expressionData = [...expressionData, ...ruleData]

          dataSet = filteredData
        }
      })

      //AND between expressions
      filteredData = expressionData
    })

    return filteredData
  }
}

export default helpers
